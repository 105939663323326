/**
 * Hits cloud function API to check if answer was correct.
 * @param {String} answer
 * @returns Promise
 */
export function answerQuizQuestion(answer) {
  // TODO: run this only on develop, via env variables
  /*return new Promise((resolve, reject) => {
    resolve({
      status: "success", // "success" / "fail"
      coupon: "F1A5A726B074130D795ECEF24F9C40"
    });
  });*/

  const API_URL = 'https://europe-west1-mindworxacademy.cloudfunctions.net/surpriseGuestCheck'

  const body = JSON.stringify({ answer: answer })

  return new Promise((resolve, reject) => {
    fetch(API_URL, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body
    })
    .then(res => res.json())
    .then(json => { resolve(json) })
    .catch(err => { reject(err) })
  })
}
