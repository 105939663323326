
/**
 * Subscribe to Mailchimp service
 * @param {*} PayloadObject - { email, audience, tags }
 */
export function mailchimpSubscribe({ email, audience, tags }) {
  const MAILCHIMP_API_URL = 'https://europe-west3-mindworxacademy.cloudfunctions.net/mailchimpListApi'
  
  // Request Payload
  const body = JSON.stringify({ 
    email,
    audience,
    tags
  })
  
  return new Promise((resolve, reject) => {
    fetch(MAILCHIMP_API_URL, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      // mode: "cors",
      body
    })
    .then(res => res.json())
    .then(json => {
      resolve(json)
    })
    .catch(err => {
      reject(err)
    })
  })
}


