import React from 'react'

import styles from "./sponsors.module.scss"
import kmpgLogo from "../../images/img/company_logos/kmpg-gs.png"
import alianzLogo from "../../images/img/company_logos/alianz-gs.png"
import forbesLogo from "../../images/img/company_logos/forbes-gs.png"
import ogilvyLogo from "../../images/img/company_logos/ogilvy-gs.png"
import orangeLogo from "../../images/img/company_logos/orange-gs.png"
import vaynermediaLogo from "../../images/img/company_logos/vaynermedia-gs.png"

function Sponsors() {
  let sponsors = [
    {
      alt: "alianz logo",
      img: alianzLogo,
      style: {width: "83px"}
    },
    {
      alt: "forbes logo",
      img: forbesLogo,
      style: {width: "73px"}
    },
    {
      alt: "vaynermedia logo",
      img: vaynermediaLogo,
      style: {width: "181px"}
    },
    {
      alt: "kmpg logo",
      img: kmpgLogo,
      style: {width: "66px"}
    },
    {
      alt: "ogilvy logo",
      img: ogilvyLogo,
      style: {width: "66px"}
    },
    {
      alt: "orange logo",
      img: orangeLogo,
      style: {width: "50px"}
    },
  ];
  return (
    <div className={styles.sponsors}>
      <div className={styles.sponsors__text}>Join learners from</div>
      <div className={styles.sponsors__logos}>
        {sponsors.map(({alt, img, style, ...rest}, index) => {
          return (
            <div
              key={index}
              className={styles.sponsors__sponsor}
            >
              <img
                alt={alt}
                src={img}
                style={{display: "block", ...style}}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Sponsors
