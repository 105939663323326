import React from "react"
import Row from "../Row"
import styles from "./styles.module.scss"
import masterclassAboutMaking from "./img/masterclass-about-making.svg"
import masterclassAboutExperts from "./img/masterclass-about-experts.svg"
import masterclassAboutParts from "./img/masterclass-about-parts.svg"

function MasterclassAboutIcons ({...rest}) {
    return (
      <Row {...rest}>
        <div className={`${styles.item} text-center`}>
          <img
            className={styles.icon}
            src={masterclassAboutParts}
            alt="parts of the world icon"
          />
          <div>Produced in 4 different parts&nbsp;of&nbsp;the&nbsp;world</div>
        </div>
        <div className={`${styles.item} text-center`}>
          <img
            className={styles.icon}
            src={masterclassAboutMaking}
            alt="2 years in the making icon"
          />
          <div>2 years in the making</div>
        </div>
        <div className={`${styles.item} text-center`}>
          <img
            className={styles.icon}
            src={masterclassAboutExperts}
            alt="experts in the field icon"
          />
          <div>Created by world's leading&nbsp;experts&nbsp;in&nbsp;the&nbsp;field</div>
        </div>
      </Row>
    )
}

export default MasterclassAboutIcons
