import React from "react"
import PropTypes from "prop-types"

import shortid from "shortid"

import styles from "./card.module.scss"

const Card = ({ children }) => {
  return (
    <>
      {children.length > 0 && (
        <ul className={styles.list}>
          {children.map(card => (
            <li className={styles.item} key={shortid.generate()}>
              {card}
            </li>
          ))}
        </ul>
      )}
    </>
  )
}

Card.propTypes = {
  children: PropTypes.array.isRequired,
}

export default Card
