import React from "react"
import Slider from "react-slick"
import PropTypes from "prop-types"

import shortid from "shortid"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import buttonPrev from "../../images/img/left.svg"
import buttonNext from "../../images/img/right.svg"

import styles from "../Testimonial/testimonial.module.scss"

const CustomSlide = props => (
  <div {...props} className={styles.slide}>
    {props.item}
  </div>
)

class TestimonialSlider extends React.Component {
  constructor(props) {
    super(props)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }
  next() {
    this.slider.slickNext()
  }
  previous() {
    this.slider.slickPrev()
  }
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      prevArrow: <></>,
      nextArrow: <></>,
      appendDots: dots => (
        <div>
          <ul style={{ margin: "0px" }} className={styles.dotsList}>
            {dots}
          </ul>
        </div>
      ),
      customPaging: () => (
        <button
          style={{
            padding: "0",
            border: "none",
            outline: "none",
            width: "8px",
            height: "8px",
          }}
          className={styles.dotButton}
        ></button>
      ),
    }
    return (
      <section className={styles.sliderWrapper}>
        <div
          style={{ position: "relative", maxWidth: "480px", margin: "0 auto" }}
        >
          <div className={styles.buttonWrapper}>
            <button className={styles.button} onClick={this.previous}>
              <img src={buttonPrev} className={styles.arrowIcon} />
            </button>
          </div>
          <div className={styles.slider}>
            <Slider
              ref={c => (this.slider = c)}
              {...settings}
              className={styles.slides}
            >
              {this.props.children.map(slide => (
                <CustomSlide item={slide} key={shortid.generate()} />
              ))}
            </Slider>
          </div>
          <div className={styles.buttonWrapper}>
            <button className={styles.buttonNext} onClick={this.next}>
              <img src={buttonNext} className={styles.arrowIcon} />
            </button>
          </div>
        </div>
      </section>
    )
  }
}

TestimonialSlider.propTypes = {
  children: PropTypes.array.isRequired,
}

export default TestimonialSlider
