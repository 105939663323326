import React, {Component} from 'react';
import {Link} from 'gatsby';
import Button from '../../button';
import Popup from '../../popup';
import modalStyles from '../../popup/popup.module.scss';
import {mailchimpSubscribe} from '../../../lib/mailchimApi';
import {validateEmail} from '../../../lib/validators';

export default class NewsletterPopup extends Component {
  constructor (props) {
    super (props);
    this.state = {
      email: '',
      agreementCheckbox: false,
      invalidEmailMsg: '',
      errorMessage: '',
    };

    this.isFormValid = this.isFormValid.bind(this)
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
  }

  isFormValid() {
    const { email, agreementCheckbox } = this.state
    return agreementCheckbox && validateEmail(email)
  }

  handleFormSubmit(e) {
    e.preventDefault ();

    if (!validateEmail (this.state.email)) {
      this.setState ({
        invalidEmailMsg: 'Email is not valid.',
        errorMessage: '',
      });
      return;
    }

    mailchimpSubscribe ({
      email: this.state.email,
      audience: 'main',
      tags: ['website subscriber'],
    })
    .then (res => {
      if (this.props.handleAfterSubmit) {
        // move this code after mailchimpSubscribe is done
        this.props.handleAfterSubmit ();
      }
    })
    .catch (err => {
      this.setState ({
        errorMessage: 'Something went wrong, please try it again.',
      });
    });
  }

  render () {
    return (
      <Popup show={this.props.show} handleClose={this.props.handleClose}>
        <h2 className={modalStyles.title}>
          Preview the Masterclass <br />
          for free.
        </h2>
        <p className={modalStyles.text}>
          Curious about the Masterclass — but unsure? Enter your email and we'll redirect you to an exclusive preview of
          what to expect.
        </p>

        <form
          className={modalStyles.form}
          // action="/newsletter/subscribe-plus"
          method="post"
          // noValidate
          onSubmit={this.handleFormSubmit}
        >
          <input
            className={modalStyles.inputText}
            name="email"
            required
            placeholder="Your email"
            autoComplete="on"
            type="email"
            onChange={e => this.setState ({email: e.target.value})}
          />
          <div className={modalStyles.message}>
            {this.state.invalidEmailMsg}
          </div>

          <div className={modalStyles.checkboxWrapper}>
            <input
              type="checkbox"
              className={modalStyles.checkboxInput}
              id="checkbox"
              onChange={_ =>
                this.setState (state => ({
                  agreementCheckbox: !state.agreementCheckbox,
                  invalidEmailMsg: '',
                }))}
            />
            <label
              htmlFor="checkbox"
              style={{
                fontSize: '12px',
                paddingLeft: '28px',
                fontWeight: '400',
              }}
            >
              {' '}
              I agree to the
              <Link className={modalStyles.link} to="/privacy-policy">
                privacy policy terms.
              </Link>
            </label>
          </div>
          <Button
            size="popup"
            type="submit"
            id="cta-popup-lead"
            disabled={!this.isFormValid()}
          >
            Give me my exclusive preview
          </Button>

          <div className={modalStyles.message}>{this.state.errorMessage}</div>

          <p className={modalStyles.noThanks} onClick={this.props.handleClose}>
            Nah, thanks — I don’t want to learn and improve.
          </p>
        </form>
      </Popup>
    );
  }
}

/*
$form.on("submit", function(event) {
  event.preventDefault();

  // if invalid data, don't send
  if (!$form.valid()) {return;}

  // serialize data
  let dataSerialized = $form.serializeArray();
  let data = dataSerialized.reduce(function(obj, item) {
      obj[item.name] = item.value;
      return obj;
  }, {});

  data.audience = "b-test";
  data.tags = ["BE_TIPS_vB"];

  $sendButton.text("Subscribing...");
  $.ajax({
      type: $form.attr('method'),
      url: "https://europe-west3-mindworxacademy.cloudfunctions.net/mailchimpListApi",
      data: data,
      dataType: 'json',
      success: function (data, textStatus, xhr) {
          $sendButton.prop("disabled", true);
          if(xhr.status === 200) {
              $sendButton.text("Successfully subscribed.");
              setTimeout(function() {
                  hideWeeklyTipsPopup();
              }, 2000)
          } else {
              $sendButton.text("Failed. Please try again.");
          }
      },
      error: function (data) {
          $sendButton.prop("disabled", true);
          $sendButton.text("Failed. Please try again.");
      }
  });
});
*/
