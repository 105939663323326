import React from 'react'
import PropTypes from 'prop-types'

import styles from "./testimonial.module.scss"

function Testimonial(props) {
  return (
    <blockquote>
      {props.quote}
      <div className={styles.description}>
        <img
          alt={`Photo of ${props.name}`}
          src={props.img}
          title={`Photo of ${props.name}`}
          className={styles.descrImg}
        />
        <div className={styles.descrText}>
          <strong>{props.name}, </strong>{props.organisation}
        </div>
      </div>
    </blockquote>
  )
}


{/* USAGE:
  <SingleTestimonial
    name="George S. Dan"
    organisation="CEO at User Nudge"
    img={SliderImgGeorgedan}
    quote={`I want to congratulate you for the work you did with Rory, Sam and
      Dan. Everybody in marketing should take this course. I teach and
      practice Behavioral Science and I will let all my students and
      clients know about the course. A job well done indeed!`}
  />
*/}

Testimonial.propTypes = {
  quote: PropTypes.string.isRequired,
  organisation: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired
}

export default Testimonial
