import React from "react"
import PropTypes from "prop-types"

import styles from "./title.module.css"

const Title = props => (
  <div className={styles.titleWrapper}>
    <h1 className={styles.mainTitleStyle}>{props.children}</h1>
  </div>
)

Title.propTypes = {
  children: PropTypes.string.isRequired,
}

export default Title
