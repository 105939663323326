import modalStyles from "../../popup/popup.module.scss"
import styles from "./styles.module.scss"
import { answerQuizQuestion } from "./lib/api"
import Button from "../../button"
import Popup from "../../popup"
import React, { useRef, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
query QuizPopupCourse {
  site {
    siteMetadata {
      pricingConfiguration {
        eur {
          ebook {
            price
            oldPrice
            url
          }
          complete {
            price
            oldPrice
            url
          }
          video {
            price
            oldPrice
            url
          }
        }
      }
    }
  }
}
`

export function QuizPopup ({show, handleClose, ...rest}) {
  const couponRef = useRef(null);
  const [answer, setAnswer] = useState(null);
  const [coupon, setCoupon] = useState(null);
  const [message, setMessage] = useState(null);
  const [title, setTitle] = useState("Who's the surprise guest?");
  const [sendButtonIsDisabled, setSendButtonIsDisabled] = useState(false);
  const [subtitle, setSubtitle] = useState("Type in your answer and win 15% off.");
  const { eur } = useStaticQuery(query).site.siteMetadata.pricingConfiguration;

  const onSubmit = e => {
    e.preventDefault();

    answerQuizQuestion(answer)
      .then(res => {
        if (res.status === "success") {
          setCoupon(res.coupon);
          setMessage(null);
          setSendButtonIsDisabled(true);
          setTitle("Congrats, you're right!");
          setSubtitle("Here's your 15% discount coupon");
        } else {
          setTitle("Ooops, try again.");
          setMessage("Hint: watch carefully the Masterclass preview, you might find the answer there.")
        }
      })
      .catch(_ => {
        setMessage("Something went wrong, please try it again.");
      });
  }

  const onCopyButtonClick = e => {
    e.preventDefault();
    couponRef.current.select();
    document.execCommand('copy');
  }

  return (
    <Popup show={show} handleClose={handleClose}>
      <h2 className={modalStyles.title}>{title}</h2>

      <p className={modalStyles.text}>{subtitle}</p>

      {!coupon ?
        (
          <>
            <form
              method="post"
              onSubmit={onSubmit}
              className={modalStyles.form}
            >
              <input
                type="text"
                name="email"
                autoComplete="off"
                placeholder="Your answer"
                className={modalStyles.inputText}
                onChange={e => setAnswer(e.target.value)}
              />

              <Button
                size="popup"
                type="submit"
                disabled={sendButtonIsDisabled}
              >Submit your answer</Button>
            </form>
          </>
        ) : (
          <>
            <input
              readOnly
              type="text"
              value={coupon}
              ref={couponRef}
              className={modalStyles.coupon}
            />

            <div
              className={styles.message}
            >
              <strong className="underline"><a className="no-link" onClick={onCopyButtonClick} href="#">Copy</a></strong> the coupon
              and <strong className="underline"><a className="no-link" href={eur.complete.url} target="_blank">insert</a></strong> it on the
              checkout page when you enroll in the course.
            </div>
          </>
        )
      }

      <div
        className={styles.message}
        dangerouslySetInnerHTML={{__html: message}}
      />

      {coupon &&
        <Button
          size="popup"
          onClick={onCopyButtonClick}
        >COPY MY COUPON</Button>
      }
    </Popup>
  )
}

export default QuizPopup;
